import React, { FC } from 'react';

import { Theme, useTheme } from '@mui/material';

import { StyledAvatar } from 'common/components/Avatars/StyledAvatar';
import OpenAiIcon from 'common/components/CustomIcons/OpenAiIcon';

export interface AvatarProps {
  initials?: string;
  isUser: boolean;
}

export const Avatar: FC<AvatarProps> = ({ initials, isUser }) => {
  const { palette } = useTheme<Theme>();

  return (
    <StyledAvatar size="small" sx={{ backgroundColor: palette.secondary.main }}>
      {isUser ? initials : <OpenAiIcon sx={{ fill: palette.neutral.light }} />}
    </StyledAvatar>
  );
};
