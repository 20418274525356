import React, { ReactNode, useCallback, useEffect, useRef } from 'react';

import AddCommentIcon from '@mui/icons-material/AddComment';
import { Box, CircularProgress, Theme, Tooltip, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ChatMessageSenderEnum } from '@zarn/vendor/dist/search';

import { ChatContext, Evidence } from 'api/chatApi/chatApi.types';
import { NoteDetails } from 'api/notesApi/notesApi.types';
import {
  BotType,
  DefaultBotTypes,
} from 'api/tenantSettingsApi/tenantSettingsApi.types';
import TrackedIconButton from 'common/components/Buttons/TrackedIconButton/TrackedIconButton';
import { LoaderContainer } from 'common/components/Loaders/LoaderContainer/LoaderContainer';
import { BoxWithoutScroll } from 'common/components/WithoutScroll/WithoutScroll';
import { ChunkHighlight } from 'common/interfaces/ChunkHighlight.interfaces';
import { selectUser } from 'containers/User/user.slice';

import { Conversation } from './Chat.types';
import { ConversationList } from './Conversation/ConversationList';
import { Greetings } from './Greetings/Greetings';
import { useChat } from './hooks/useChat';
import { useChatMessages } from './hooks/useChatMessages';
import { Message } from './Message';
import { MessageInput } from './MessageInput';
import { ModeButtons } from './ModeButtons';
import { SavingStatus } from './SavingStatus/SavingStatus';

export interface ChatProps<T extends object = {}> {
  botParams?: Record<string, any>;
  context: ChatContext;
  defaultBotType: DefaultBotTypes;
  disabled?: boolean;
  greetings?: ReactNode;
  header?: ReactNode;
  isEvidenceDialog?: boolean;
  isLoading?: boolean;
  isSaving: boolean;
  onEvidenceChunkClick?: (
    chunks: ChunkHighlight[],
    docIdOrChunkId: string | undefined
  ) => void;
  onEvidenceItemClick?: (evidence: Evidence) => void;
  onSave: (
    content: string,
    note: NoteDetails | { content: string } | null
  ) => Promise<NoteDetails>;
  placeholder?: string;
  predefinedConversation?: Conversation<T> | null;
  renderContextAction?: (conversation?: Conversation<T>) => ReactNode;
  supportedBotTypes: BotType[];
}

export const Chat = <T extends object = {}>({
  botParams,
  context,
  defaultBotType,
  disabled,
  greetings,
  header,
  isEvidenceDialog,
  isLoading,
  isSaving,
  onEvidenceChunkClick,
  onEvidenceItemClick,
  onSave,
  placeholder,
  predefinedConversation,
  renderContextAction,
  supportedBotTypes,
}: ChatProps<T>) => {
  const { t } = useTranslation('common');
  const { palette } = useTheme<Theme>();
  const chatMessagesRef = useRef<HTMLDivElement | null>(null);
  const { buildDefaultMessage } = useChatMessages();
  const user = useSelector(selectUser);

  const {
    conversation,
    error,
    isLoadingMessage,
    isLoadingNote,
    onReset,
    onSendMessage,
  } = useChat({
    botParams,
    context,
    onSave,
    predefinedConversation,
  });
  const isError = !!error;

  const scrollTo = useCallback((top: number) => {
    chatMessagesRef.current?.scroll({
      behavior: 'smooth',
      top,
    });
  }, []);

  const scrollToBottom = useCallback(
    (scrollHeight: number) => {
      scrollTo(scrollHeight);
    },
    [scrollTo]
  );

  useEffect(() => {
    if (chatMessagesRef.current) {
      scrollToBottom(chatMessagesRef.current.scrollHeight);
    }
  }, [conversation, isLoadingNote, scrollToBottom]);

  const handleSubmit = useCallback(
    (message: string) =>
      onSendMessage(buildDefaultMessage(message, defaultBotType)),
    [buildDefaultMessage, onSendMessage, defaultBotType]
  );

  const history = useHistory();
  const isCurrentPagePDFOrTags =
    history.location.pathname.includes('/pdf') ||
    history.location.pathname.includes('/tags');

  const isNewChatDisabled =
    isLoadingMessage ||
    isLoadingNote ||
    !conversation ||
    !isCurrentPagePDFOrTags;
  const isChatDisabled =
    disabled || isLoadingMessage || isLoadingNote || isSaving || isLoading;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      {header}
      <LoaderContainer loading={isLoadingNote}>
        {(greetings || !conversation) && (
          <Greetings greetings={greetings} isLoading={isLoading} />
        )}

        {!conversation && (
          <ModeButtons
            disabled={disabled}
            supportedBotTypes={supportedBotTypes}
            onClick={onSendMessage}
          />
        )}

        {conversation && (
          <>
            <BoxWithoutScroll
              ref={chatMessagesRef}
              sx={{ height: '100%', mt: 2, overflow: 'hidden scroll' }}
            >
              {renderContextAction?.(conversation)}

              <ConversationList
                conversation={conversation}
                initials={user?.initials}
                isEvidenceDialog={isEvidenceDialog}
                onEvidenceChunkClick={onEvidenceChunkClick}
                onEvidenceItemClick={onEvidenceItemClick}
              />

              {isError && (
                <Message
                  isContent={true}
                  sender={ChatMessageSenderEnum.Bot}
                  sx={{ backgroundColor: palette.error.light }}
                >
                  <Tooltip title={error?.message}>
                    {t('chat.message.error')}
                  </Tooltip>
                </Message>
              )}

              {isLoadingMessage && (
                <Message isContent={true} sender={ChatMessageSenderEnum.Bot}>
                  <CircularProgress
                    color="secondary"
                    data-testid="loading-icon"
                    size={18}
                  />
                </Message>
              )}
            </BoxWithoutScroll>

            <SavingStatus isSaving={isSaving} />
          </>
        )}
      </LoaderContainer>

      <Box
        style={{
          alignItems: 'center',
          display: 'flex',
          gap: '0.25rem',
          padding: '0rem 0.5rem 1rem 0.5rem',
        }}
      >
        <Tooltip title={t('chat.moreMenu.start')}>
          <Box>
            <TrackedIconButton
              data-testid="new-chat-button"
              disabled={isNewChatDisabled}
              onClick={onReset}
            >
              <AddCommentIcon />
            </TrackedIconButton>
          </Box>
        </Tooltip>

        <MessageInput
          disabled={isChatDisabled}
          isLoading={isLoadingMessage || isLoading}
          placeholder={placeholder}
          onSubmit={handleSubmit}
        />
      </Box>
    </Box>
  );
};
