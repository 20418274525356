import { useHistory } from 'react-router-dom';

import { useNavigationWithState } from 'common/hooks/useNavigationWithState';

export const useTagChatOpen = () => {
  const history = useHistory();
  const { push } = useNavigationWithState();

  const openTagChat = (tagId: number, noteIdValue: number) => {
    const suffix = `&noteId=${noteIdValue}`;

    if (history.location.pathname.includes('tags')) {
      push(`${history.location.pathname}?tab=chat${suffix}`);
    } else {
      push(`tags/${tagId}?tab=chat${suffix}`);
    }
  };

  return { openTagChat };
};
