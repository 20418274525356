import React, { FC, useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useZoomLevel } from '@zarn/pdf-viewer';

import { AnnotationHighlight, NoteDetails } from 'api/notesApi/notesApi.types';
import {
  BotType,
  DefaultBotTypes,
} from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { SearchEngineEnum } from 'common/enums';
import { useQuerySearchParams } from 'common/hooks/useQuerySearchParams';
import { ChunkHighlight } from 'common/interfaces/ChunkHighlight.interfaces';
import { compareDocuments } from 'common/utils/docIdHelpers';

import { Chat } from '../../Chat';
import DocStatus from '../../RetrievalUnit/DocStatus/DocStatus';
import { RetrievalUnitData } from '../../RetrievalUnit/RetrievalUnitData.interface';
import {
  buildChatContext,
  getPageDimensions,
  getTopCoordinate,
  mapChunk,
} from '../helpers';
import { useSaveChat } from '../hooks/useSaveChat';

interface Props {
  defaultBotType: DefaultBotTypes;
  docData: RetrievalUnitData;
  docId: string;
  onAddHighlights: (highlights: AnnotationHighlight[]) => void;
  otherDocumentEvidenceChunkClick: (chunkId: string | null) => void;
  scrollTo: (top: number) => void;
  searchEngine?: SearchEngineEnum;
  supportedBotTypes: BotType[];
}

export const ChatOfPdfViewer: FC<Props> = ({
  defaultBotType,
  docData,
  docId,
  onAddHighlights,
  otherDocumentEvidenceChunkClick,
  scrollTo,
  searchEngine,
  supportedBotTypes,
}) => {
  const { t } = useTranslation('common');
  const { setParams } = useQuerySearchParams();
  const { zoomLevel } = useZoomLevel();

  const handleCreateNote = useCallback(
    async (newNote: NoteDetails) => {
      setParams({
        noteId: `${newNote.id}`,
      });
    },
    [setParams]
  );

  const { isSaving, saveChat } = useSaveChat({
    docId,
    onNoteCreated: handleCreateNote,
  });

  const handleEvidenceChunkClick = useCallback(
    (chunks: ChunkHighlight[], docIdOrChunkId: string | undefined = '') => {
      const isSameDocument = compareDocuments(docId, docIdOrChunkId);

      if (isSameDocument) {
        const { height: pageHeight, width: pageWidth } = getPageDimensions();
        const temporaryHighlights = chunks.map(
          mapChunk(pageWidth, pageHeight, zoomLevel)
        );
        onAddHighlights(temporaryHighlights);

        const rect = chunks[0];
        if (!rect) {
          return;
        }
        scrollTo(
          getTopCoordinate({ ...rect, y1: rect.y1 }, getPageDimensions().height)
        );
      } else {
        otherDocumentEvidenceChunkClick(docIdOrChunkId ?? null);
      }
    },
    [
      onAddHighlights,
      scrollTo,
      zoomLevel,
      otherDocumentEvidenceChunkClick,
      docId,
    ]
  );

  const context = useMemo(
    () =>
      buildChatContext(
        searchEngine ?? SearchEngineEnum.ZetaAlpha,
        docId,
        docData
      ),
    [docData, docId, searchEngine]
  );

  const greetings =
    docData.status && docData.status.title !== 'SUCCESS' ? (
      <DocStatus status={docData.status} sx={{ ml: 1, mr: 1 }} />
    ) : undefined;

  return (
    <Chat
      context={context}
      defaultBotType={defaultBotType}
      disabled={docData.status && docData.status.title !== 'SUCCESS'}
      greetings={greetings}
      isSaving={isSaving}
      placeholder={t('chat.message.input.placeholderDocument')}
      supportedBotTypes={supportedBotTypes}
      onEvidenceChunkClick={handleEvidenceChunkClick}
      onSave={saveChat}
    />
  );
};
