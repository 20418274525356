import React, { FC, useState } from 'react';

import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { Box, Dialog, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Evidence } from 'api/chatApi/chatApi.types';
import TrackedIconButton from 'common/components/Buttons/TrackedIconButton/TrackedIconButton';
import CollectionDialogContent from 'common/components/Dialogs/CollectionDialogContent/CollectionDialogContent';
import DialogActionTitle from 'common/components/Dialogs/DialogActionTitle';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import DocsCollection from 'containers/Docs/DocsCollection';
import { useOrganizeDocIds } from 'containers/Docs/hooks/useOrganizeDocIds';
import { useAllEvidenceHits } from 'containers/SearchWidgets/QuestionAnsweringWidget/hooks/useAllEvidenceHits';

import { EvidenceDoc } from './EvidenceItem/EvidenceDoc';

interface EvidenceButtonWithDialogProps {
  answerContent: React.ReactNode;
  items: Evidence[];
}

export const EvidenceButtonWithDialog: FC<EvidenceButtonWithDialogProps> = ({
  answerContent,
  items,
}) => {
  const { t } = useTranslation('common');

  const [dialogOpen, setDialogOpen] = useState(false);

  const toggleEvidence = () => {
    setDialogOpen(!dialogOpen);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const { docsData } = useAllEvidenceHits({
    evidences: items || [],
  });
  const organizeDocIds = useOrganizeDocIds(docsData);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '0.25rem',
        }}
      >
        <Tooltip title={t('chat.evidence.tooltip')}>
          <TrackedIconButton
            aria-label="show evidence"
            eventName={TrackEventName.ChatEvidenceClicked}
            size="small"
            onClick={toggleEvidence}
          >
            <LibraryBooksIcon />
          </TrackedIconButton>
        </Tooltip>
      </Box>
      <Dialog
        aria-label="Evidences for the current chat answer"
        maxWidth="md"
        open={dialogOpen}
        fullWidth
        onClose={handleClose}
      >
        <DialogActionTitle onClose={handleClose}>
          <Typography variant="body2">{answerContent}</Typography>
        </DialogActionTitle>

        <CollectionDialogContent dividers>
          {docsData && docsData.length > 0 && (
            <DocsCollection organizeDocIds={organizeDocIds}>
              {docsData.map((doc, i) => (
                <EvidenceDoc doc={doc} i={i} items={items} key={i} />
              ))}
            </DocsCollection>
          )}
        </CollectionDialogContent>
      </Dialog>
    </>
  );
};
