import React, { FC } from 'react';

import { Box } from '@mui/material';

import { Evidence } from 'api/chatApi/chatApi.types';
import { ChunkHighlight } from 'common/interfaces/ChunkHighlight.interfaces';

import { Conversation } from '../Chat.types';

import { ConversationMessageItem } from './ConversationMessageItem';

export interface ConversationListProps {
  conversation: Conversation;
  initials?: string;
  isEvidenceDialog?: boolean;
  onEvidenceChunkClick?: (
    chunks: ChunkHighlight[],
    docIdOrChunkId: string | undefined
  ) => void;
  onEvidenceItemClick?: (evidence: Evidence) => void;
}

export const ConversationList: FC<ConversationListProps> = ({
  conversation,
  initials,
  isEvidenceDialog,
  onEvidenceChunkClick,
  onEvidenceItemClick,
}) => (
  <Box data-testid="ConversationList">
    {conversation.messages.map((message, index) => (
      <ConversationMessageItem
        initials={initials}
        isEvidenceDialog={isEvidenceDialog}
        key={`message-${index}`}
        message={message}
        onEvidenceChunkClick={onEvidenceChunkClick}
        onEvidenceItemClick={onEvidenceItemClick}
      />
    ))}
  </Box>
);
