import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { Conversation } from '../../../../../containers/Chat/Chat.types';
import { Avatar } from '../../../../../containers/Chat/Message/Avatar';
import NoteAnnotationHighlightItem from '../../NoteAnnotationHighlightItem';

interface Props {
  conversation: Conversation;
}

export const ShortConversationList: FC<Props> = ({ conversation }) => {
  const message = conversation.messages[0];

  return (
    <Box data-testid="ShortConversationList">
      <Box sx={{ display: 'flex' }}>
        <Avatar isUser={false} />
        <Typography
          sx={{
            flex: 1,
            maxHeight: '48px',
            ml: 1,
            mr: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          variant="body2"
        >
          {message.image && (
            <NoteAnnotationHighlightItem
              contentBody={message.image}
              contentType={'image'}
            />
          )}
          {message.content}
        </Typography>
      </Box>
    </Box>
  );
};
