import React, { FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';

import { PageSection } from 'common/components/PageSection/PageSection';

import ContentLoader from '../../common/components/Loaders/ContentLoader/ContentLoader';
import { PageContainer } from '../../common/components/Page/PageContainer/PageContainer';
import { PageMainContent } from '../../common/components/PageWrapper/PageMainContent';
import { MainSidebar } from '../../containers/MainSidebar';
import { MainToolbar } from '../../containers/MainToolbar';

import { useInfoPage } from './useInfoPage';
import { useStyles } from './useStyles';

export const InfoPage: FC = () => {
  const classes = useStyles();
  const { content, title } = useInfoPage();

  return (
    <Box alignItems="flex-start" display="flex" sx={{ minHeight: '100vh' }}>
      <MainToolbar />
      <MainSidebar />
      <PageMainContent>
        <PageContainer maxWidth="xl">
          <Grid spacing={4} container>
            <Grid md={8} sm={12} item xs>
              <PageSection sectionTitle={title}>
                <Typography
                  className={classes.content}
                  component="div"
                  gutterBottom
                >
                  {content ? (
                    <ReactMarkdown>{content}</ReactMarkdown>
                  ) : (
                    <ContentLoader />
                  )}
                </Typography>
              </PageSection>
            </Grid>
          </Grid>
        </PageContainer>
      </PageMainContent>
    </Box>
  );
};
