import React, { FC } from 'react';

import SettingsIcon from '@mui/icons-material/Settings';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Popover,
  useTheme,
} from '@mui/material';
import { AppTheme } from 'app/theme';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TrackedNavLink from 'common/components/Links/TrackedNavLink/TrackedNavLink';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { useAuth } from 'containers/Auth/hooks/useAuth';
import { selectUser } from 'containers/User/user.slice';

import { useInfoPagesLink } from '../../common/hooks/useInfoPagesLink';

import InviteUserButton from './InviteUserButton';
import { NavbarProfileLogoutButtons } from './NavbarProfileLogoutButtons/NavbarProfileLogoutButtons';
import { useStyles } from './styles';

// TODO: test
export const NavbarProfile: FC = () => {
  const { t } = useTranslation('common');
  const { isAuthenticated } = useAuth();
  const user = useSelector(selectUser);
  const classes = useStyles();
  const theme = useTheme<AppTheme>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuId = 'navbarProfileMenu';
  const isMenuOpen = Boolean(anchorEl);

  const aboutLink = useInfoPagesLink('about');
  const featuresLink = useInfoPagesLink('features');
  const contactLink = useInfoPagesLink('contact');

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleProfileMenuClose = () => setAnchorEl(null);

  if (!isAuthenticated) return null;

  return (
    <>
      <IconButton
        aria-controls={menuId}
        aria-haspopup="true"
        aria-label="account of current user"
        color="contrast"
        data-testid="avatar-button"
        edge="end"
        size="large"
        onClick={handleProfileMenuOpen}
      >
        <Avatar
          className={classes.avatar}
          sx={{
            backgroundColor: theme.palette.header.iconColor,
            color: theme.palette.header.fontColor,
          }}
        >
          {user?.initials}
        </Avatar>
      </IconButton>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        classes={{ paper: classes.profilePaper }}
        data-testid="avatar-menu"
        id={menuId}
        open={isMenuOpen}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={handleProfileMenuClose}
      >
        <ListItem className={classes.profileListItem}>
          <ListItemAvatar>
            <Avatar>{user?.initials}</Avatar>
          </ListItemAvatar>

          {user && (
            <ListItemText
              primary={`${user.firstName} ${user.lastName}`}
              secondary={user.email}
            />
          )}
        </ListItem>

        <Box pt={2} px={2}>
          <InviteUserButton />
        </Box>

        <List>
          <TrackedNavLink
            className={classes.link}
            eventName={TrackEventName.ProfileSettingsClicked}
            to="/settings/profile"
          >
            <ListItem button onClick={handleProfileMenuClose}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>

              <ListItemText primary={t('navBar.settings')} />
            </ListItem>
          </TrackedNavLink>

          <Box my={1}>
            <Divider />
          </Box>

          <TrackedNavLink
            className={classes.link}
            eventName={TrackEventName.AboutLinkClicked}
            to={aboutLink}
          >
            <ListItem button onClick={handleProfileMenuClose}>
              <ListItemText primary={t('navBar.about')} />
            </ListItem>
          </TrackedNavLink>

          <TrackedNavLink
            className={classes.link}
            eventName={TrackEventName.ProfileFeaturesClicked}
            to={featuresLink}
          >
            <ListItem button onClick={handleProfileMenuClose}>
              <ListItemText primary={t('navBar.features')} />
            </ListItem>
          </TrackedNavLink>

          <TrackedNavLink
            className={classes.link}
            eventName={TrackEventName.ProfileContactClicked}
            to={contactLink}
          >
            <ListItem button onClick={handleProfileMenuClose}>
              <ListItemText primary={t('navBar.contact')} />
            </ListItem>
          </TrackedNavLink>

          <TrackedNavLink
            className={classes.link}
            eventName={TrackEventName.ProfileReleaseNotesClicked}
            to="/product/release-notes"
          >
            <ListItem button onClick={handleProfileMenuClose}>
              <ListItemText primary={t('navBar.releaseNotesTitle')} />
            </ListItem>
          </TrackedNavLink>

          <Box my={1}>
            <Divider />
          </Box>

          <NavbarProfileLogoutButtons />
        </List>
      </Popover>
    </>
  );
};
